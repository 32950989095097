@media print {
  body {
    header.dashboard-header {
      ul.dashboard-top-navigation {
        display: none;
      }
    }
    aside.dashboard-sider {
      display: none;
    }
  }
  
  table {
    page-break-inside: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }
}

@page {
  margin: 10mm;
}