.estimate {
  .ant-row {
    margin: 32px 0;
  }

  .anticon-info-circle, .anticon-question-circle {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.45);
    cursor: help;
    writing-mode: horizontal-tb;
    margin-inline-start: 4px;
  }

  .ant-table-tbody {
    td.ant-table-cell {
      .part {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }

      .part-number {
        text-align: right;
        margin-left: 8px;
      }

      .ant-form-item {
        &, .ant-row {
          margin: 0;
        }
      }
    }
  }

  .ant-table-content {
    margin-bottom: 8px;
  }

  .estimate-type-selection {
    .ant-btn {
      &:hover {
        font-size: 18px;
      }
    }

    .estimate-type-selection-btn {
      height: 200px !important;
    }
  }
}

@media print {
  .estimate {
    > div {
      width: 100% !important;
    }
    
    #view-estimate-options,
    #view-estimate-parts {
      page-break-after: always;
    }
  }
}