.dashboard {
  min-height: 100vh;

  .dashboard-header {
    height: 5rem;
    line-height: 80px;
    padding: 0;

    .brand {
      color: $white;
      float: left;
      font-size: 2rem;
      font-weight: 500;
      max-height: 100%;
      padding: 0 2rem;
      vertical-align: middle;
      
      img {
        height: 4rem;
        margin-top: -10px;
        margin-right: .5rem;
      }
      
      span {
        vertical-align: middle;
      }
    }
    
    .dashboard-top-navigation {
      justify-content: right;
      padding: 0 2rem;
      background-color: $primary-dark;
      
      .ant-menu-item {
        svg {
          color: $white;
          font-size: 2rem;
          vertical-align: middle;
        }
      }
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    background-color: $light-grey;
    color: $text-dark;
    margin-top: 0;
    margin-bottom: 0;
  }

  .dashboard-sider {
    height: 100%;
    width: 200px;

    .dashboard-side-navigation {
      border-right: 0;
      height: 100%;  

      .ant-menu-item {
        color: $slate;
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: $light-blue;
      }

      .ant-menu-title-content:hover {
        color: $dark-blue;
        
        a {
          color: $dark-blue;
        }
      }
      
      .ant-menu-light .ant-menu-submenu-title:hover  {
        color: $dark-blue;
      }
      
      .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: $dark-blue;
      }
      
      .ant-menu-submenu-selected {
        color: $dark-blue;
      }
      
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: $light-blue;
      
        &::after {
          border-right: 3px solid $dark-blue;
        }
      
        .anticon, a {
          color: $dark-blue;
        }
      }
      
      .ant-menu-light .ant-menu-item:hover {
        color: $dark-blue;
      }

      .ant-menu-item:not(.ant-menu-item-only-child) {
        background-color: $light-grey;
        margin-top: 0;
        margin-bottom: 0;

        &.ant-menu-item-selected {
          background-color: $light-blue;
        }
      }
    }
  }

  .dashboard-content {
    background: $eggshell;
    padding: 24px;
    margin: 0;
  }
}