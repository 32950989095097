$white: #fff;
$primary-dark: #000A1B;
$red: #9C1937;
$red-hover: #711329;
$dark-blue: #0F4E85;
$light-blue: #DCE7EF;
$eggshell: #F0EFEB;
$grey: #9DA7A7;
$light-grey: #C0C6C6;
$slate: #586565;
$text-dark: #192026;
