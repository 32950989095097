#resources {
  .ant-row {
    margin: 32px 0;
  }

  .single-resource {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .ant-list {
    .ant-list-header {
      background-color: $red;
      color: $white;
      padding: 12px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      position: relative;

      .resource-category-title-controls {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);

        > .anticon {
          margin-right: 5px;
        }
      }
    }

    .ant-list-item {
      padding: 12px;
      background-color: $white;
      border-bottom: 1px solid $eggshell;

      &:hover {
        background-color: #f0f2f5;
      }

      .ant-list-item-meta-description {
        color: $slate;
      }

      .ant-list-item-meta {
        .ant-list-item-meta-title {
          a {
            color: $text-dark;
          }

          a:hover {
            color: $red;
          }
        }

        .anticon {
          font-size: 24px;
        }
      }
    }
  }
}