body {
  min-height: 100vh;
}

#footer-content {
  font-size: 8px;
  text-align: right;

  span {
    margin-left: .25rem;
  }
}

.ant-layout {
  background-color: $grey;
}

.ant-layout-header {
  background-color: $primary-dark;
}

.ant-btn-dangerous {
  color: $red;
  border-color: $red;

  &:hover { 
    color: $red-hover;
    border-color: $red-hover;
  }
}

.ant-page-header {
  margin-bottom: 16px;
}

.ant-menu-title-content:hover {
  color: $dark-blue;
  
  a {
    color: $dark-blue;
  }
}

.ant-menu-light .ant-menu-submenu-title:hover  {
  color: $dark-blue;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: $dark-blue;
}

.ant-menu-submenu-selected {
  color: $dark-blue;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $light-blue;

  &::after {
    border-right: 3px solid $dark-blue;
  }

  .anticon, a {
    color: $dark-blue;
  }
}

.ant-menu-light .ant-menu-item:hover {
  color: $dark-blue;
}

.ant-btn-default:not(.ant-btn-dangerous) {
  &:hover {
    color: $dark-blue;
    border-color: $dark-blue;
  }
}

.ant-btn-primary {
  background-color: $red;
  border-color: $red;

  &:hover {
    background-color: $red-hover;
    border-color: $red-hover;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $red;
  border-color: $red;
}

// Hide expand icon on table rows that don't need it
.ant-table-row.no-expand {
  & + .ant-table-expanded-row, .ant-table-row-expand-icon {
    display: none;
  }
}

.pointer-cursor {
  cursor: pointer;
}